import axios from 'axios';
import store from '../store.js';
import { refreshToken, logout } from '../actions/authActions.js';

// Utility function to get CSRF token from cookies
const getCSRFToken = () => {
  const name = 'csrftoken';
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
  return null;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,  // Ensure cookies are sent in requests
});

// Function to set Authorization and CSRF tokens
export const setAuthToken = (accessToken) => {
  if (accessToken) {
    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    console.log('Access Token Set:', accessToken);
  } else {
    delete api.defaults.headers.common['Authorization'];
  }

  // Set CSRF token
  const csrfToken = getCSRFToken();
  if (csrfToken) {
    api.defaults.headers.common['X-CSRFToken'] = csrfToken;
    console.log('CSRF Token Set:', csrfToken);
  } else {
    console.error('CSRF token not found!');
  }
};

// Flag to track token refresh status
let isRefreshing = false;
let failedQueue = [];

// Function to process the queue of failed requests
const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Adding a response interceptor
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    console.log("API call failed with 401 Unauthorized:", originalRequest.url);

    // If the refresh token request itself fails
    if (error.response.status === 401 && originalRequest.url === '/api/token/refresh/') {
      console.log("Refresh token expired or invalid. Logging out...");
      isRefreshing = false;  // Reset the flag
      processQueue(error, null);  // Reject all queued requests
      store.dispatch(logout());
      window.location.href = '/login';
      return Promise.reject(error);
    }

    // If the error is a 401 and it's not a retry
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        console.log("Token is already being refreshed, queuing the request...");
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
        .then(token => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return api(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        console.log("Attempting to refresh token...");
        const newToken = await store.dispatch(refreshToken());

        if (!newToken) {
          console.log("No new token obtained, logging out...");
          processQueue(new Error('Failed to refresh token'), null);  // Reject all queued requests
          store.dispatch(logout());
          return Promise.reject(new Error('Failed to refresh token'));
        }

        console.log("New token obtained, setting token and retrying original request...");
        setAuthToken(newToken);
        localStorage.setItem('access_token', newToken);
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        processQueue(null, newToken);
        return api(originalRequest);
      } catch (err) {
        console.error("Error during token refresh:", err);
        processQueue(err, null);  // Reject all queued requests
        store.dispatch(logout());

        return Promise.reject(err);
      } finally {
        isRefreshing = false;  // Reset the flag
      }
    }

    return Promise.reject(error);
  }
);

export default api;
