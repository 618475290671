import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//API
import { fetchTrades, addProject, updateProject, fetchUsers } from '../../apislice';

//MODAL
import FullScreenModal from '../utils/FullScreenModal';

//MUI
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Box, Card, FormGroup, Typography, CardActionArea, CardContent, Autocomplete } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { InputBase } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Select as MuiSelect, MenuItem, InputLabel, FormControl, FormHelperText, Switch, FormControlLabel} from '@mui/material';
import { Button, Input } from '@mui/material';
import Chip from '@mui/material/Chip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { List, ListItem, ListItemText } from '@mui/material';

//FORMIK
import { ErrorMessage } from 'formik';
import { useField, useFormikContext } from 'formik';
import { Form, Formik, Field } from 'formik';

//YUP
import * as Yup from 'yup';
import { SignalCellularNullRounded } from '@mui/icons-material';


//COMPONENTS
import GridItemCard from '../utils/GridItemCard';

import { format } from 'date-fns';

import Select from 'react-select';
import DataGridComponent from '../utils/DataGrid';



const ProjectsManage = ({ open, handleClose, projectId}) => {
    const dispatch = useDispatch();
    const ProjectIdRef = useRef(projectId);
    const [value, setValue] = React.useState('1');
    const [isEditMode, setIsEditMode] = React.useState(false);
    const projects = useSelector((state) => state.api.projects.data);
    const projectData = projects.find((projects) => projects.id === projectId);
    const userId = useSelector((state) => state.auth.email.id);
    const companies = useSelector((state) => state.api.companies.data);
    const projectProjects = projects.filter(project => project.projectId === projectId);
    const users = useSelector((state) => state.api.users.data) || [];
    const userIds = Array.isArray(projectData?.users) ? projectData.users : [];
    const projectUsers = users.filter(user => userIds.includes(user.id));
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const availableUsers = users.filter(user => !userIds.includes(user.id));







    
    useEffect(() => {
        ProjectIdRef.current = projectId;
  
    }, [projectId]);


    useEffect(() => {
        if(projectId) {
            setIsEditMode(true);
        }else {
            setIsEditMode(false);
        }

        dispatch(fetchUsers());
    }, [dispatch]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    }

    // const handleUserAdd = () => {
    //   const updatedUserIds = [...userIds, ...selectedUsers];
    //   const updatedProject = { ...projectData, users: updatedUserIds }; // Ensure to include all required fields
    //   dispatch(updateProject({ projectId: projectId, project: updatedProject }));
    // };
    
    const handleUserAdd = () => {
      const updatedUserIds = [...userIds, ...selectedUsers];
      const updatedProject = { ...projectData, users: updatedUserIds };
      dispatch(updateProject({ projectId: projectId, project: updatedProject }))
        .unwrap()
        .then(() => {
          alert('Users added successfully');
          setSelectedUsers([]); // Clear selected users after adding
        })
        .catch((error) => {
          alert('Failed to add users');
          console.error('Error:', error);
        });
    };

    const handleUserRemove = (userId) => {
      const updatedUserIds = userIds.filter(id => id !== userId);
      const updatedProject = { ...projectData, users: updatedUserIds }; // Ensure to include all required fields
      dispatch(updateProject({ projectId: projectId, project: updatedProject }));
    };


    const FormikTextField = ({ name, label, disabled, ...otherProps }) => {
        const [field, meta] = useField(name);
        const [isFocused, setIsFocused] = React.useState(false);
        const errorText = meta.error && meta.touched ? meta.error : '';


        return (
            <TextField
                {...field}
                {...otherProps}
                label={label}
                disabled={disabled}
                helperText={errorText}
                error={!!errorText}
                size="small"
                sx={{ mb: 1.3}}
                fullWidth
            />
        );
    };

    const FormikMuiSelect = ({ label, options, ...props }) => {
        const [field, meta] = useField(props);
        return (
          <FormControl fullWidth error={meta.touched && Boolean(meta.error)} size="small" >
            <InputLabel >{label}</InputLabel>
            <MuiSelect {...field} {...props} label={label} sx={{ mb: 1.3}}>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </MuiSelect>
            {meta.touched && meta.error ? (
              <FormHelperText>{meta.error}</FormHelperText>
            ) : null}
          </FormControl>
        );
      };

      

    const FormikPercentageField = ({ name, label }) => {
        const { setFieldValue } = useFormikContext();
        const [field, meta] = useField(name);
      
        const handleChange = (event) => {
          const value = event.target.value;
          setFieldValue(name, value ? parseFloat(value) : '');
        };
      
        return (
          <FormControl fullWidth error={meta.touched && Boolean(meta.error)} >
            <TextField
              {...field}
              type="number"
              label={label}
              onChange={handleChange}
              InputProps={{
                endAdornment: <span>%</span>,
              }}
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error ? meta.error : ''}
              size="small"
              sx = {{mb: 1.3}}
            />
          </FormControl>
        );
      };

    const FormikNumberField = ({ name, label }) => {
        const { setFieldValue } = useFormikContext();
        const [field, meta] = useField(name);
        
        const handleChange = (event) => {
            const value = event.target.value;
            setFieldValue(name, value ? parseFloat(value) : '');
        };
        
        return (
            <FormControl fullWidth error={meta.touched && Boolean(meta.error)} >
                <TextField
                    {...field}
                    type="text" // Use type="text" to allow custom handling of input
                    label={label}
                    onChange={handleChange}
                    inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*', // Pattern to restrict input to digits only
                    }}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                    size="small"
                    sx={{ mb: 1.3}}
                />
            </FormControl>
        );
    };

    const FormikDateField = ({ name, label, ...otherProps }) => {
        const { setFieldValue } = useFormikContext();
        const [field, meta] = useField(name);
      
        return (
            <FormControl fullWidth error={meta.touched && Boolean(meta.error)} >
                <LocalizationProvider dateAdapter={AdapterDateFns}  >
                    <DatePicker
                    {...field}
                    {...otherProps}
                    label={label}
                    size="small"
                    sx={{ mb: 1.3}}
                    format='dd/MM/yyyy'
                    fullWidth
                    value={field.value || null} // Handle null values to avoid warnings
                    onChange={(value) => {
                        setFieldValue(field.name, value);
                    }}
                    slotProps={{ textField: {size: 'small'}}}
                
                    

                    />
                </LocalizationProvider>
            </FormControl>
        );
      };

      const FormikCurrencyField = ({ name, label }) => {
        const { setFieldValue } = useFormikContext();
        const [field, meta] = useField(name);
      
        const handleChange = (event) => {
          let value = event.target.value;
          // Remove non-numeric characters except for decimal point and minus sign
          value = value.replace(/[^\d.-]/g, '');
      
          // Check if the value is a valid number
          const parsedValue = parseFloat(value);
          if (!isNaN(parsedValue)) {
            setFieldValue(name, parsedValue.toFixed(2)); // Set value with two decimal places
          } else {
            setFieldValue(name, ''); // Set empty string if value is not a valid number
          }
        };
      
        return (
          <FormControl fullWidth error={meta.touched && Boolean(meta.error)}>
            <TextField
              {...field}
              type="text" // Use type="text" for custom handling
              label={label}
              onChange={handleChange}
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9.-]*', // Allow digits, decimal point, and minus sign
              }}
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error ? meta.error : ''}
              size="small"
            />
          </FormControl>
        );
      };



      const FormikFileField = ({ name, ...otherProps }) => {
        const [field, meta, helpers] = useField(name);
      
        const handleChange = (event) => {
          const files = event.target.files;
          let fileList = [];
          if (files) {
            for (let i = 0; i < files.length; i++) {
              // Add files to the fileList array
              fileList.push(files.item(i));
            }
          }
          // Update Formik's state with the fileList array
          helpers.setValue(fileList);
        };





      
        return (
          <>
            <Input
              {...field}
              {...otherProps}
              project_type="file"
              onChange={handleChange}
              style={{ display: 'none' }} // Hide the native file input
              id="file-upload"
              multiple // Remove this prop if only single file upload is required
            />
            <label htmlFor="file-upload">
              <Button variant="contained" component="span">
                Upload Document
              </Button>
            </label>
            {meta.touched && meta.error ? <div style={{ color: 'red' }}>{meta.error}</div> : null}
          </>
        );
      };
    
      const FormikSwitch = ({ name, label, ...props }) => {
        const { setFieldValue } = useFormikContext();
        const [field] = useField(name);
      
        return (
          <FormControlLabel
            control={
              <Switch
                {...props}
                checked={field.value}
                onChange={(event, checked) => setFieldValue(name, checked)}
                sx={{ mb: 1.3}}
              />
            }
            label={label}
          />
        );
      };


    const typeChoices = [
        { value: 'New Build', label: 'New Build' },
        { value: 'Refurbishment', label: 'Refurbishment' },
        { value: 'Maintenance', label: 'Maintenance' },
        { value: 'Fit Out', label: 'Fit Out' },
        { value: 'Design & Build', label: 'Design & Build' },
        { value: 'Civil Engineering', label: 'Civil Engineering' },
        { value: 'Other', label: 'Other' },
      ];

    const statusChoices = [
        { value: 'In Progress', label: 'In Progress' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Planning', label: 'Planning' },
        { value: 'On Hold', label: 'On Hold' },
        { value: 'Cancelled', label: 'Cancelled' },
    ];




    let initialValues;

    if (projectData) {
        initialValues = {
            project_name: projectData.project_name,
            project_refernce: projectData.project_refernce,
            start_date: projectData.start_date,
            end_date: projectData.end_date,
            location: projectData.location,
            status: projectData.status,
            client: projectData.client,
            budget: projectData.budget,
            project_description: projectData.project_description,
            project_type: projectData.project_type,
            retention: projectData.retention,
            damages: projectData.damages,
            vat_percentage: projectData.vat_percentage,
            defects_period: projectData.defects_period,
            vatable: projectData.vatable,
            payment_terms: projectData.payment_terms,
            notes: projectData.notes,
        };

        if (projectData.buyer !== null) {
            initialValues.buyer = projectData.buyer;
        }

        if (projectData.project_manager !== null) {
            initialValues.project_manager = projectData.project_manager;
        }

        if (projectData.employers_agent !== null) {
            initialValues.employers_agent = projectData.employers_agent;
        }

        if (projectData.principal_designer !== null) {
            initialValues.principal_designer = projectData.principal_designer;
        }
    } else {
        initialValues = {
            project_name: '',
            project_refernce: '',
            project_description: '',
            start_date: '',
            end_date: '',
            location: '',
            status: '',
            client: '',
            budget: '',
            project_description: '',
            project_type: '',
            retention: '',
            damages: '',
            vat_percentage: '',
            defects_period: '',
            vatable: '',
            payment_terms: '',
            notes: '',
        };
    }






    const formTitle = projectData ? `Edit Project: ${projectData.project_name}` : 'Add Project';

    const clientOptions = companies
        .filter((company) => company.category === 'Client')
        .map((company) => ({
        value: company.id,
        label: company.name,
    }));

    const employers_agentOptions = companies
        .filter((company) => company.category ==='Consultant')
        .map((company) => ({
            value: company.id,
            label: company.name,
    }));

    const buyerOptions = users
        .filter((user) => user.job_title === 'Buyer')
        .map((user) => ({
            value: user.id,
            label: user.first_name + ' ' + user.last_name,
    }));


    const userColumns = [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'name', headerName: 'Name', flex: 1},
      { field: 'email', headerName: 'Email', flex: 1 },
      { field: 'job_title', headerName: 'Job Title', flex: 1},
      { field: 'company', headerName: 'Company', flex: 1,             
        valueGetter: (params) => {
        const company = companies.find(company => company.id === params.row.company);
        return company ? company.name : '';
      }},
      {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        renderCell: (params) => (
          <Button variant="outlined" color="secondary" onClick={() => handleUserRemove(params.row.id)}>
            Remove
          </Button>
        ),
      },
    ];
  
    const userRows = projectUsers.map((user) => ({
      id: user.id,
      name: user.first_name + ' ' + user.last_name,
      email: user.email,
      role: user.job_title,
      company: user.company,

    }));

    //Validation Schema
    const validationSchemea = Yup.object({
        // name: Yup.string()
        //   .required('Required')
        //   .trim()
        //   .lowercase()
        //   .matches(/^[a-z0-9 ]+$/i, 'Must be only letters and numbers')
        //   .test('isUnique', 'This project already exists', function (value) {
        //     if (isEditMode && projectData && value.toLowerCase() === projectData.project_name.toLowerCase()) {
        //       return true;
        //     }
        //     const projectNames = projects
        //       .filter(project => project.id !== projectData?.id)
        //       .map(project => project.project_name.toLowerCase());
        //     return !projectNames.includes(value.toLowerCase());
        //   }),
        // project_refernce: Yup.string().required('Required').max(20),
        // start_date: Yup.date().required('Required'),
        // end_date: Yup.date().required('Required'),
        // location: Yup.string().required('Required'),
        // buyer: Yup.string().required('Required'),
        // project_manager: Yup.string().required('Required'),
        // status: Yup.string().required('Required'),
        // client: Yup.string().required('Required'),
        // budget: Yup.number().required('Required').positive(),
        // project_description: Yup.string().required('Required'),
        // project_type: Yup.string().required('Required'),
        // retention: Yup.number().required('Required').positive(),
        // damages: Yup.number()
        //     .required('Required')
        //     .positive(),


        // vat_percentage: Yup.number().required('Required').positive(),
        // defects_period: Yup.string().required('Required'),
        // vatable: Yup.boolean().required('Required'),
        // employers_agent: Yup.string().required('Required'),
        // principal_designer: Yup.string().required('Required'),
        // payment_terms: Yup.number()
        //     .required('Required')
        //     .positive(),
        // notes: Yup.string().required('Required'),
      });




    return (
        <FullScreenModal open={open} handleClose={handleClose} title={formTitle}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchemea}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                
                    const formData = new FormData();

                    Object.keys(values).forEach(key => {
                        formData.append(key, values[key])
                    })



                    
                    


                    formData.append('user', userId);

                    if (values.start_date) {
                      const formattedstartDate = format(new Date(values.start_date), 'yyyy-MM-dd');
                      formData.append('start_date', formattedstartDate);
                    }
                    if (values.end_date) {
                      const formattedendDate = format(new Date(values.end_date), 'yyyy-MM-dd');
                      formData.append('end_date', formattedendDate);
                    }

                    





                    console.log('Submitting: ', values);
                    console.log('FormData:', formData);
                    console.log(projectId)

                    const allUserIds = [...userIds, ...selectedUsers];
                    allUserIds.forEach(userId => {
                      formData.append('users', userId);
                    });

                    if (isEditMode) {

                        
                        dispatch(updateProject({projectId: projectId, project: formData}))
                        .unwrap()
                        .then((result) => {
                            alert('project Updated');
                            setSubmitting(false);
                            resetForm();
                            handleClose();
                        });
                    } else {
                    console.log('Adding project:', formData);
                    dispatch(addProject(formData))
                    .unwrap()
                    .then((result) => {
                        alert('project Added');
                        setSubmitting(false);
                        resetForm();
                        handleClose();
                    })
                    .catch((error) => {
                        alert('An error occurred');
                        console.log('Error:', error);
                        setSubmitting(false);
                    });

                }}}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form>

                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                    <Tab label="Project Details" value="1" />
                                    <Tab label="Users" value="2" />


                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                
                                <Grid2 container spacing={2}>
                                    <Grid2 item xs={3} spacing={2}>

                                        <FormGroup>
                                            <FormikTextField name="project_name" project_type="text" label="Project Name" />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="project_refernce" project_type="text" label="Reference" disabled={isEditMode} />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="address" project_type="text" label="Address" />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikDateField name="start_date" label="Start Date" />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikDateField name="end_date" label="End Date" />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="location" project_type="text" label="Location" />
                                        </FormGroup>
                                        
                                        <FormGroup>
                                            <FormikMuiSelect name="client" label="Client" options={clientOptions} />
                                        </FormGroup>
                                    
                                        <FormGroup>
                                            <FormikTextField name="vat_number" project_type="text" label="VAT Number"/>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikTextField name="project_description" project_type="text" label="Description" />  
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikMuiSelect
                                                name="project_type"
                                                label="Type"
                                                options={typeChoices}
                                                value={values.project_type}
                                            
                                            />

                                        </FormGroup>



                                    </Grid2>
                                    <Grid2 item xs={3} spacing={2}>

                                        
                                            {/* <FormGroup className="mb-auto">
                                                <FormikMuiSelect name="buyer" label="Buyer" options={buyerOptions} />
                                            </FormGroup> */}
                                            <FormGroup>
                                                <FormikTextField name="buyer" project_type="text" label="Buyer" />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormikTextField name="project_manager" project_type="text" label="Project Manager" />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormikMuiSelect name="status" label="Status" options={statusChoices} />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormikPercentageField name="retention" label="Retention" />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormikNumberField name="damages" project_type="text" label="Damages per week" />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormikTextField name="defects_period" project_type="text" label="Defects Period" />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormikCurrencyField name="budget" label="Budget" />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormikSwitch name="vatable" label="Vatable" />
                                            </FormGroup>
                                            {values.vatable && (
                                                <FormGroup>
                                                    <FormikPercentageField name="vat_percentage" label="VAT Percentage" />
                                                </FormGroup>
                                            )}
                                            <FormGroup>
                                                <FormikNumberField name="payment_terms" project_type="text" label="Payment Terms (days)" />
                                            </FormGroup>



                                            


                                    </Grid2>
                                    <Grid2 item xs={3} spacing={2}>
                                        <FormGroup>
                                             <FormikMuiSelect
                                                name="employers_agent"
                                                label="Employers Agent"
                                                options={employers_agentOptions}
                                                value={values.employers_agent}
                                            
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                             <FormikTextField
                                                name="principal_designer"
                                                label="Principal Designer"
                                            

                                            />
                                        </FormGroup>

                                    </Grid2>

                                </Grid2>

                            </TabPanel>
                            <TabPanel value="2" label="Users">
                              <Grid2 container spacing={2}>
                                <Grid2 item xs={12} spacing={2}>
                                  <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="select-users-label">Select Users</InputLabel>
                                    <MuiSelect
                                      labelId="select-users-label"
                                      multiple
                                      value={selectedUsers}
                                      onChange={(e) => setSelectedUsers(e.target.value)}
                                      renderValue={(selected) => (
                                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                              {selected.map((value) => (
                                                  <Chip key={value} label={users.find(user => user.id === value)?.email || value} />
                                              ))}
                                          </Box>
                                      )}
                                  >
                                      {availableUsers.map((user) => (
                                          <MenuItem key={user.id} value={user.id}>
                                              {user.email}
                                          </MenuItem>
                                      ))}
                                  </MuiSelect>
                                  </FormControl>
                                  <Button variant="contained" color="primary" onClick={handleUserAdd} sx={{ mb: 2 }}>
                                    Add Users
                                  </Button>
                                  <DataGridComponent columns={userColumns} rows={userRows} pageSize={5} checkboxSelection={false} />
                                </Grid2>
                              </Grid2>
                            </TabPanel>




                        
                        </TabContext>
                        <Box sx={{ position: 'absolute', right: 0, bottom: 0, p: 2 }}>
                            <Button variant="outlined" color="secondary" onClick={handleClose} sx={{mr: 1}}>
                                Close
                            </Button>
                            <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                                Submit
                            </Button>
                        </Box>



                    </Form>
                    )}
                </Formik>
        </FullScreenModal>
    );
}

export default ProjectsManage;