import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Container, Box } from '@mui/material';
import { useLocation, useParams, useNavigate } from 'react-router-dom'; // Added useNavigate for redirection
import api from '../../api/api';

const ResetPassword = () => {
    const query = new URLSearchParams(useLocation().search);
    const { uid, token } = useParams();
    const navigate = useNavigate(); // For navigation after success

    const [isFirstTimeSetup, setIsFirstTimeSetup] = useState(false); // To track if this is the first time password setup
    const [message, setMessage] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    // Fetch CSRF token on component mount
    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                await api.get('/csrf-cookie/', {
                    credentials: 'include',  // Ensures cookies are included in the request/response
                });
            } catch (err) {
                console.error('Error fetching CSRF token:', err);
            }
        };

        fetchCsrfToken();
    }, []);

    useEffect(() => {
        const purpose = query.get('purpose');
        if (purpose === 'welcome') {
            setIsFirstTimeSetup(true); // If accessed via a welcome email link
        }
    }, [query]);

    // Helper function to get the CSRF token from cookies
    const getCookie = (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const csrftoken = getCookie('csrftoken'); // Get CSRF token from cookie

            const response = await api.post(`/password-reset-confirm/${uid}/${token}/`, {
                uid,
                token,
                password,
            }, {
                headers: {
                    'X-CSRFToken': csrftoken,  // Include CSRF token in the request header
                },
                credentials: 'include', // Include credentials (cookies)
            });

            if (response.status === 200) {
                setMessage('Password has been set successfully.');
                setTimeout(() => {
                    navigate('/login'); // Redirect to login after a short delay
                }, 3000); // Delay for 3 seconds to show the message before redirecting
            } else {
                setError('An error occurred while setting the password.');
            }
        } catch (error) {
            setError('An error occurred while setting the password.');
            console.error('Error:', error);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    {isFirstTimeSetup ? 'Set Your Password' : 'Reset Password'}
                </Typography>
                {message && (
                    <Typography color="primary" variant="body2">
                        {message}
                    </Typography>
                )}
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="New Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="new-password"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        autoComplete="new-password"
                    />
                    {error && (
                        <Typography color="error" variant="body2">
                            {error}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {isFirstTimeSetup ? 'Set Password' : 'Reset Password'}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default ResetPassword;
