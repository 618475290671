import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFormTemplates, fetchProjects, submitForm, updateForm } from '../../apislice';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Modal,
  CircularProgress,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Drawer,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const FormEditor = ({ form, onClose }) => {
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedProject, setSelectedProject] = useState('');
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true); // To toggle template selection
  const templates = useSelector(state => state.api.formTemplates.data);
  const projects = useSelector(state => state.api.projects.data);
  const getCurrentUserId = useSelector(state => state.auth.email.id);

  // Fetch templates and projects when the component mounts
  useEffect(() => {
    dispatch(fetchFormTemplates());
    dispatch(fetchProjects());
  }, [dispatch]);

  // Pre-load form data if editing an existing form
  useEffect(() => {
    if (form && templates.length > 0) {
      const template = templates.find(t => t.id === form.template);
      if (!template) return;

      let selectedVersion = null;
      if (form.template_version) {
        selectedVersion = template.versions.find(v => v.id === form.template_version);
      }

      if (!selectedVersion) {
        selectedVersion = getLatestPublishedVersion(template);
      }

      if (selectedVersion) {
        setSelectedTemplate(selectedVersion);
        const alignedFormData = alignFormDataWithTemplateFields(selectedVersion.fields, form.data);
        setFormData(alignedFormData);
      }

      setSelectedProject(form.project);
    }
  }, [form, templates]);

  // Helper to find the latest published version of the template
  const getLatestPublishedVersion = (template) => {
    return template.versions
      .filter(v => !v.is_draft && v.visible)
      .sort((a, b) => b.version_number - a.version_number)[0];
  };

  const visibleTemplates = templates.filter(template => {
    const visibleVersion = template.versions.some(v => !v.is_draft && v.visible);
    return template.visible && visibleVersion;
  });

  // Function to get template name by version template ID
  const getTemplateNameByVersion = (version, templates) => {
    const matchingTemplate = templates.find(template => template.id === version.template);
    return matchingTemplate ? matchingTemplate.name : 'Template not found';
  };

  // Align form data with template fields for editing
  const alignFormDataWithTemplateFields = (fields, data) => {
    const alignedData = {};
    fields.forEach(field => {
      alignedData[field.id] = data[field.id] || ''; // Align field id with data
    });
    return alignedData;
  };

  const renderFormField = (field) => {
    const commonProps = {
      key: field.id,
      label: field.label || '',  // Ensure there's a default label
      required: field.required || false, // Ensure required is handled properly
      value: formData[field.id] || '',
      onChange: (e) => handleFormChange({ ...formData, [field.id]: e.target.value }),
      fullWidth: true,
      margin: 'normal',
      size: 'small',
      InputProps: { style: { fontSize: field.font_size || 14 } } // Set a default font size
    };
  
    if (field.item_type === 'table') {
      if (!formData[field.id]?.rows) {
        // Initialize rows with one empty row if not set
        handleFormChange({
          ...formData,
          [field.id]: {
            rows: [Array(field.columns.length).fill('')] // Initialize with one empty row
          },
        });
      }
  
      const handleAddRow = () => {
        const updatedRows = [...(formData[field.id]?.rows || [])];
        const emptyRow = Array(field.columns.length).fill('');
        updatedRows.push(emptyRow);
  
        handleFormChange({
          ...formData,
          [field.id]: { ...formData[field.id], rows: updatedRows },
        });
      };
  
      const handleCellChange = (rowIndex, colIndex, value) => {
        const updatedRows = [...(formData[field.id]?.rows || [])]; // Create a shallow copy of the rows array
        const updatedRow = [...updatedRows[rowIndex]]; // Create a shallow copy of the specific row
      
        updatedRow[colIndex] = value; // Update the specific cell in the row
        updatedRows[rowIndex] = updatedRow; // Replace the row with the updated one
      
        handleFormChange({
          ...formData,
          [field.id]: { ...formData[field.id], rows: updatedRows }, // Update the rows in the state
        });
      };
  
      return (
        <Box key={field.id} sx={{ mb: 2, overflowX: 'auto' }}>
          <Typography variant="h6" gutterBottom>
            {field.label}
          </Typography>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                {field.columns?.map((col, colIndex) => (
                  <th key={colIndex} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
                    {col.label}
                  </th>
                )) || <th>No columns available</th>}
              </tr>
            </thead>
            <tbody>
              {formData[field.id]?.rows?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <td key={colIndex} style={{ border: '1px solid #ddd', padding: '8px' }}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={cell || ''}
                        onChange={(e) => handleCellChange(rowIndex, colIndex, e.target.value)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <Button variant="contained" color="primary" size="small" sx={{ mt: 2 }} onClick={handleAddRow}>
            Add Row
          </Button>
        </Box>
      );
    }
  
    // Handle other field types here
    switch (field.item_type) {
      case 'textInput':
        return <TextField {...commonProps} />;
      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={formData[field.id] || false}
                onChange={(e) => handleFormChange({ ...formData, [field.id]: e.target.checked })}
                size="small"
              />
            }
            label={field.label}
            sx={{ mb: 2 }}
          />
        );
      case 'dropdown':
        return (
          <FormControl fullWidth sx={{ mb: 2 }} key={field.id} size="small">
            <InputLabel>{field.label || 'Select'}</InputLabel>
            <Select
              value={formData[field.id] || ''}
              onChange={(e) => handleFormChange({ ...formData, [field.id]: e.target.value })}
            >
              {field.options?.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case 'dateInput':
        return (
          <FormControl fullWidth key={field.id} sx={{ mb: 2 }} size="small">
            <TextField
              {...commonProps}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        );
      case 'heading':  // New heading field case
        return (
          <Typography
            key={field.id}
            variant={field.size || 'h6'}  // Supports dynamic heading size (e.g., h1, h2, h3, etc.)
            gutterBottom
            align={field.alignment || 'left'}  // Supports dynamic alignment
            sx={{ mb: 2 }}
          >
            {field.label}
          </Typography>
        );
      default:
        return null;
    }
  };
  

  // Handle template selection
  const handleTemplateSelect = (template) => {
    const latestVersion = getLatestPublishedVersion(template);
    setSelectedTemplate(latestVersion);
    setFormData({}); // Clear form data when a new template is selected
    setSidebarOpen(false); // Close sidebar when template is selected
  };

  // Handle form changes
  const handleFormChange = (data) => {
    setFormData(data);
  };

  // Handle project selection
  const handleProjectSelect = (event) => {
    setSelectedProject(event.target.value);
  };

  // Handle form submission
  const handleSubmit = (isDraft = false) => {
    if (!selectedProject || !selectedTemplate || !formData) {
      alert('Please select a project, a form template, and fill out the form before submitting.');
      return;
    }
  
    // Validate required fields
    const missingRequiredFields = selectedTemplate.fields.some(field => {
      const fieldValue = formData[field.id]; // Get the form value for this field
  
      // If the field is required and its value is empty or undefined, mark it as missing
      if (field.required && (!fieldValue || fieldValue.trim() === '')) {
        return true;
      }
      return false;
    });
  
    if (missingRequiredFields) {
      alert('Please fill out all required fields.');
      setLoading(false);
      return;
    }
  
    setLoading(true);
  
    const formSubmission = {
      project: selectedProject,
      template_version: selectedTemplate.id,
      template: selectedTemplate.template,
      created_by: getCurrentUserId,
      updated_by: getCurrentUserId,
      data: formData,
      status: isDraft ? 'draft' : 'submitted',
    };
  
    // If the form has an ID, it's an update. Otherwise, it's a new submission
    const submitAction = form?.id
      ? dispatch(updateForm({ formId: form.id, form: formSubmission })) // Update existing form
      : dispatch(submitForm({ formId: selectedTemplate.template_id, form: formSubmission })); // Create new form
  
    submitAction
      .then(() => {
        alert(`Form ${isDraft ? 'saved as draft' : 'submitted'} successfully!`);
        setLoading(false);
        onClose();
      })
      .catch(() => {
        alert(`Failed to ${isDraft ? 'save draft' : 'submit form'}.`);
        setLoading(false);
      });
  };
  
  

  return (
    <Modal open={true} onClose={onClose} aria-labelledby="form-editor-modal" sx={{ display: 'flex' }}>
      <Box sx={modalStyle}>
        {/* Only show the drawer with template list and back button if not in edit mode */}
        {!form && (
          <Drawer
            variant="persistent"
            open={sidebarOpen}
            sx={{
              width: '300px',
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: '300px',
                boxSizing: 'border-box',
              },
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography variant="h6">Select a Template</Typography>
              <List>
                {visibleTemplates.map(template => {
                  const latestVersion = getLatestPublishedVersion(template);
                  return (
                    <ListItem
                      button
                      key={template.id}
                      onClick={() => handleTemplateSelect(template)}
                    >
                      <ListItemText primary={`${template.name} (Version ${latestVersion.version_number})`} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Drawer>
        )}

        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', transition: 'width 0.3s ease' }}>
            <IconButton onClick={onClose} sx={{ position: 'absolute', right: 16, top: 16 }}>
                <CloseIcon />
            </IconButton>
          
            {/* Only show the back arrow if not in edit mode */}
            {!form && (
              <IconButton onClick={() => setSidebarOpen(true)} sx={{ position: 'absolute', left: 16, top: 16 }}>
                <ArrowBackIcon />
              </IconButton>
            )}

            <Typography variant="h4" gutterBottom align="center">
                {selectedTemplate
                    ? `Form: ${getTemplateNameByVersion(selectedTemplate, templates)} (Version ${selectedTemplate.version_number})`
                    : 'New Form'}
            </Typography>


          {/* Check if the template is visible */}
          {selectedTemplate && selectedTemplate.visible ? (
            <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, pr: 2 }}>
              {/* Project Selector */}
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel id="project-select-label">Select Project</InputLabel>
                <Select
                  labelId="project-select-label"
                  id="project-select"
                  value={selectedProject}
                  onChange={handleProjectSelect}
                  label="Select Project"
                >
                  <MenuItem value="">
                    <em>Select a project</em>
                  </MenuItem>
                  {projects.map(project => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.project_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Form Fields */}
              {selectedTemplate && selectedTemplate.fields.map(field => renderFormField(field))}

            </Box>
          ) : (
            <Typography variant="h6" color="error" align="center">
                Select a template to begin
            </Typography>
          )}

          {selectedTemplate && selectedTemplate.visible && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, borderTop: '1px solid #e0e0e0' }}>
              {/* Only display 'Save Draft' if the form is not already submitted */}
              {form?.status !== 'submitted' && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSubmit(true)}  // Save as draft
                  disabled={loading}
                  startIcon={loading && <CircularProgress size={20} />}
                >
                  {form?.id ? 'Save Draft' : 'Save as Draft'}
                </Button>
              )}
              
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit(false)}  // Submit or Update depending on status
                disabled={loading}
                startIcon={loading && <CircularProgress size={20} />}
              >
                {form?.status === 'draft' ? 'Submit' : form?.id ? 'Update' : 'Submit'}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  display: 'flex',
  flexDirection: 'row',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  width: '80%',
  height: '80%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export default FormEditor;
