// import React, { useState, useEffect } from 'react';
// import { connect, useSelector } from 'react-redux';
// import { loginUser } from '../actions/authActions';
// import { useNavigate, Link } from 'react-router-dom';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';

// import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

// const Login = ({ loginUser }) => {
//   const navigate = useNavigate();
//   const loginStatus = useSelector((state) => state.auth.loginStatus);
//   const [formData, setFormData] = useState({
//     email: '',
//     password: '',
//   });

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     loginUser(formData)
//       .then((sucess) => {
//         navigate('/');
//       })

//   };

//   useEffect(() => {
//     if (loginStatus === 'success') {
//       navigate('/');
//     }
//   }, [loginStatus, navigate]);

//   return (
//     <div className="login-form-container" >
//       <div className="login-form-header">
//         <Form onSubmit={handleSubmit}>
//           <Form.Group>
            
            
//             <h2>Login</h2>
            

//             <FloatingLabel
//               controlId="floatingInput"
//               label="Email address"
//               className="mb-3">

//               <Form.Control 
//                 type="email" 
//                 placeholder="name@example.com" 
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required 
//               />
//             </FloatingLabel>

//             <FloatingLabel 
//               controlId="floatingPassword" 
//               label="Password"
//               className='mb-3'
//             >
//               <Form.Control 
//                 type="password" 
//                 placeholder="Password" 
//                 name="password"
//                 value={formData.password}
//                 onChange={handleChange}
//                 required 
//               />
//             </FloatingLabel>
//             {/* Forgot Password */}








//           </Form.Group>
//           <Grid2 container spacing={2}>
//             <Grid2 item xs={12} sm={6}>
//               <Button type="submit">Login</Button>
//             </Grid2>
//             <Grid2 item xs={12} sm={6}>
//               <Link to="/forgot-password">Forgot Password?</Link>
//             </Grid2>
//           </Grid2>
//           <Button type="submit">Login</Button>
//           <Link to="/forgot-password">Forgot Password?</Link>
//         </Form>
//       </div>
//     </div>
//   );

// };

// export default connect(null, { loginUser}) (Login);


import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { loginUser } from '../actions/authActions';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Box, Button, TextField, Typography, Link, Paper, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Adjust primary color here
    },
    secondary: {
      main: '#dc004e', // Adjust secondary color here
    },
  },
  typography: {
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
  },
});

const Login = ({ loginUser }) => {
  const navigate = useNavigate();
  const loginStatus = useSelector((state) => state.auth.loginStatus);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser(formData).then(() => {
      navigate('/');
    });
  };

  useEffect(() => {
    if (loginStatus === 'success') {
      navigate('/');
    }
  }, [loginStatus, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Paper elevation={4} sx={{ p: 5, mt: 8 }}>
          <Typography variant="h4" align="center" >Login</Typography>
          <form onSubmit={handleSubmit} noValidate>
            <TextField
              fullWidth
              label="Email address"
              variant="outlined"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              sx={{ my: 2, mt: 4}}
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />

            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ py: 1.5, mb: 1.5 }}>
              Login
            </Button>
            <Link component={RouterLink} to="/forgot-password" variant="body2" sx={{ display: 'block', mb: 2 }}>
              Forgot Password?
            </Link>
          </form>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default connect(null, { loginUser })(Login);