import * as React from 'react';
//REACT-REDUX
import { useSelector } from 'react-redux';

//REACT-ROUTER-DOM
import { Link } from 'react-router-dom';
//MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

//MUI_TABS
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

//COMPONENT UTILS
import GridItemCard from './utils/GridItemCard';


const gridData = [
  {
    title: 'Stock',
    description: 'Click to view',
    link: '/stock'
  }
]


const HomeMui = () => {
  const user = useSelector((state) => state.auth.email);
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  

  return (
    <Grid2 container spacing={3}>
      <Grid2 item xs={12} marginTop={2}>
        <h1>Welcome, {user.first_name}</h1>
      </Grid2>

      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Stock" value="1" />
              {/* <Tab label="Purchasing" value="2" />
              <Tab label="Item Three" value="3" /> */}
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid2 container spacing={3}>
              {gridData.map((item, index) => (
                
              <Grid2 item key={index}>
                <GridItemCard link={item.link} title={item.title} description={item.description} />

              </Grid2>
              ))}


            </Grid2>
          </TabPanel>
          {/* <TabPanel value="2">Item Two</TabPanel>
          <TabPanel value="3">Item Three</TabPanel> */}
        </TabContext>
      </Box>


    </Grid2>
  );
}

export default HomeMui;