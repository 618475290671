// src/reducers/authReducer.js

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_SUCCESS
} from '../actions/actionTypes';

const initialState = {
  email: null,
  token: null,
  refresh: null,
  first_name: null,
  last_name: null,
  groups: null,
  error: null,
  isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        token: action.payload.access,
        refresh: action.payload.refresh,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        groups: action.payload.groups,
        isAuthenticated: true,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        refresh: null,
        email: null,
        first_name: null,
        last_name: null,
        groups: null,
        isAuthenticated: false,
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...initialState, // Reset state to initial state on logout
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.access,
        isAuthenticated: true,
        error: null,
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        token: null,
        refresh: null,
        isAuthenticated: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
