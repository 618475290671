import '../../App.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanies, deleteProject, fetchProjects, fetchClients, newProjectFromWebSocket, updateProjectFromWebSocket, deleteProjectFromWebSocket, fetchUsers } from '../../apislice.js';

import ProjectsAddForm from './ProjectsAdd.js'
import ProjectsEditForm from './ProjectsEdit.js';
import ProjectsManage from './ProjectsManage.js';

import formatDateUK from '../UKDate.js';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';

import { setAuthToken } from '../../api/api.js';
import { render } from 'react-dom';
import api from '../../api/api.js';

import { connectWebSocket, sendMessage } from '../../websocketService';
import { useUserGroup } from '../users/userGroup.js';

import PermissionDenied from '../permissionDenied.js';

// MUI
import { DataGrid } from '@mui/x-data-grid';
import { Button, Drawer, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddCircleOutline, Delete, Edit, GetApp, Refresh } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2.js';
import { Container } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import Chip from '@mui/material/Chip';

const ProjectsHome = () => {
    const dispatch = useDispatch();
    const isApproved = useUserGroup(['Admin', 'Manager', 'Director', 'Operative']);
    const [loading, setLoading] = useState(false);
    const [showProjectAddForm, setProjectAddForm] = useState(false);
    const [showProjectEditForm, setProjectEditForm] = useState(false);
    const [selectedProjectId, setprojectId] = useState(null);
    const [showEditForm, setShowEditForm] = useState(false);
    const state = useSelector((state) => state.api);
    const accessToken = useSelector((state) => state.auth.email.access);
    const projects = useSelector((state) => state.api.projects.data);
    const status = useSelector((state) => state.api.projects.status);
    const projectStatus = [...new Set(projects.map(project => project.status))];
    const [selectedStatus, setSelectedStatus] = useState('In Progress');
    const currentUserId = useSelector(state => state.auth.email.id);
    const error = useSelector((state) => state.api.error);
    const [visibleEntries, setVisibleEntries] = useState(10);
    const [filters, setFilters] = useState({ reference: '', name: '', status: '', category: '' });
    const [searchText, setSearchText] = useState('');
    const [showProjectsManage, setShowProjectsManage] = useState(false);
    const [showManageModal, setShowManageModal] = useState(false);
    const users = useSelector((state) => state.api.users.data);
    const [sortModel, setSortModel] = useState([
        {
            field: 'id',
            sort: 'desc',
        },
    ]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const companies = useSelector((state) => state.api.companies.data);

    const categoryChoices = [
        { value: 'Planning', label: 'Planning' },
        { value: 'In Progress', label: 'In Progress' },
        { value: 'Completed', label: 'Completed' },
                { value: 'start_date', label: 'Start Date' },
        { value: 'end_date', label: 'End Date' },

    ];

    const statusChoices = [
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' },
    ];

    const filteredProjects = projects.filter((project) => {
      const searchLowered = searchText.toLowerCase();
  
      const matchesSearchText = 
          (project.project_name && project.project_name.toLowerCase().includes(searchLowered)) ||
          (project.project_reference && project.project_reference.toLowerCase().includes(searchLowered)) ||
          (project.status && project.status.toLowerCase().includes(searchLowered)) ||
          (project.client && project.client.toLowerCase().includes(searchLowered)) ||
          (project.budget && project.budget.toString().toLowerCase().includes(searchLowered)) ||
          (project.start_date && new Date(project.start_date).toLocaleDateString('en-GB').toLowerCase().includes(searchLowered)) ||
          (project.end_date && new Date(project.end_date).toLocaleDateString('en-GB').toLowerCase().includes(searchLowered));
  
      const matchesCategory = selectedCategory ? project.status === selectedCategory : true;
  
      return matchesSearchText && matchesCategory;
  });

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(fetchProjects());
                dispatch(fetchClients());
                dispatch(fetchCompanies());
                dispatch(fetchUsers());
            } catch (error) {
                // Handle errors, possibly update status to 'failed'
            }
        };

        if (status === 'idle') {
            fetchData();
        }
    }, [status, dispatch]);

    const handleRefreshClick = () => {
        dispatch(fetchProjects())
        .then((result) => {
            alert('Projects Updated!');
        })
        .catch((error) => {
            alert('An error occurred');
        });
    };

    useEffect(() => {
        const handleWebSocketMessage = (data) => {
          console.log('Websocket message received: ', data);
  
          if (data.message && data.message.action === 'created') {
            if (currentUserId && data.message.user !== currentUserId) {
                console.log('Dispatching newProjectFromWebSocket: ', data.message);
                dispatch(newProjectFromWebSocket(data.message));
            }
        } else if (data.message && data.message.action === 'updated') {
            if (currentUserId && data.message.user !== currentUserId) {
                console.log('Dispatching updateProjectFromWebSocket: ', data.message);
                dispatch(updateProjectFromWebSocket(data.message));
            }
        } else if (data.message && data.message.action === 'deleted') {
            if (currentUserId && data.message.user !== currentUserId) {
                console.log('Dispatching deleteProjectFromWebSocket: ', data.message.id);
                dispatch(deleteProjectFromWebSocket(data.message.id));
            }
        }
    }
  
        connectWebSocket('projects',handleWebSocketMessage);
        
      }, [dispatch, currentUserId]);




    const handleCategoryClick = (categoryValue) => {
        setSelectedCategory(prevCategory => prevCategory === categoryValue ? '' : categoryValue);
    };

    const handleProjectEditForm = (projectId) => {
        setprojectId(projectId);
        setProjectEditForm(true);
        console.log('Edit project Form', projectId);
    };

    const handleAddprojectForm = () => {
        setProjectAddForm(true);
        console.log('Add project Form');
    };

    const handleProjectsManage = () => {
        setShowManageModal(!showManageModal);
    };

    const handleProjectDelete = (projectId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this project?');

        if (confirmDelete) {
            console.log('Delete project', projectId);
            dispatch(deleteProject(projectId));
        } else {
            console.log('Delete Cancelled');
        }
    };

    const handleDownloadPdf = async (projectId) => {
        console.log('Download PDF', projectId);
        const response = await fetch(`http://localhost:8000/api/pdf/project/?id=${projectId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/pdf',
            },
        });

        if (response.ok) {
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'project.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    };

    if (!isApproved) {
        return <PermissionDenied />;
    }

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const exportToCsv = (filename, rows) => {
        const csvContent = rows.map(row => Object.values(row).join(",")).join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const prepareDataForExport = () => {
        return projects.map(project => ({
            ID: project.id,
            Name: project.project_name,
            Reference: project.reference,
            Status: project.status,
            Category: project.category,
            Client: project.clients,
            Type: project.trades_names.join(', '),
            'Date Created': formatDateUK(project.created_at),
            'Date Modified': formatDateUK(project.updated_at),
        }));
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'project_name',
            headerName: 'Name',
            flex: 1,
            editable: false,
        },
        {
            field: 'project_refernce',
            headerName: 'Reference',
            flex: 1,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            editable: false,
            flex: 1,
        },
        {
            field: 'client',
            headerName: 'Client',
            flex: 1,
            editable: false,
            valueGetter: (params) => {
                const clientCompany = companies.find(company => company.id === params.value);
                return clientCompany ? clientCompany.name : 'Unknown';
            }
        },
        {
            field: 'budget',
            headerName: 'Budget',
            flex: 1,
            editable: false,
        },
        {
            field: 'start_date',
            headerName: 'Start Date',
            flex: 1,
            editable: false,
            valueGetter: (params) => {
                const valueFormatted = new Date(params.value).toLocaleDateString();
                return `${valueFormatted}`;
            },
        },
        {
            field: 'end_date',
            headerName: 'Compeltion Date',
            flex: 1,
            editable: false,
            valueGetter: (params) => {
                const valueFormatted = new Date(params.value).toLocaleDateString();
                return `${valueFormatted}`;
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <strong>
                    <Button
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => handleProjectEditForm(params.row.id)}
                    >
                        Edit
                    </Button>
                    <Button
                        color="secondary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => handleProjectDelete(params.row.id)}
                    >
                        Delete
                    </Button>
                </strong>
            ),
        },
    ];

    const renderProjectList = () => {
        if (status === 'loading') {
            return <div>Loading...</div>;
        }

        if (status === 'failed') {
            return <div>
                Error: An error occurred while fetching data.
                <button onClick={handleRefreshClick}>Try again</button>
                {error && <div>Error Details{error}</div>}
            </div>;
        }

        return (
            <Container maxWidth style={{ padding: '10px 0px 0px 0px' }}>
                <Container maxWidth style={{ padding: '0px 0px 0px 0px' }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h4" gutterBottom>
                            Projects
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        sx={{ fontSize: '12px', padding: '5px 0px 8px 16px' }}
                    >
                        <Grid item sx={{ order: { xs: 1, sm: 1 } }}>
                        </Grid>
                        <Grid item sx={{ order: { xs: 1, sm: 2 } }}>
                            <Box display="flex" gap={1}>
                                <Button
                                    color="primary"
                                    onClick={handleProjectsManage}
                                    startIcon={<AddCircleOutline />}
                                >
                                    Add
                                </Button>
                                <Button onClick={handleRefreshClick} startIcon={<Refresh />}>
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => exportToCsv('projects.csv', prepareDataForExport())}
                                    startIcon={<GetApp />}
                                >
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '0px' }}>
                        <TextField
                            id="search"
                            size='small'
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Search Projects..."
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        {categoryChoices.map((category) => (
                            <Chip
                                key={category.value}
                                label={category.label}
                                onClick={() => handleCategoryClick(category.value)}
                                style={{ marginRight: '10px', marginBottom: '10px', marginTop: '10px' }}
                                variant={selectedCategory === category.value ? "filled" : "outlined"}
                                color={selectedCategory === category.value ? "primary" : "default"}
                            />
                        ))}
                    </Grid>
                    <Box sx={{ height: `calc(100vh - 300px)` }}>
                        <DataGrid
                            rows={filteredProjects}
                            columns={columns}
                            pageSize={visibleEntries}
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}
                            autoHeight={false}
                            stickyHeader
                            disableColumnResize={false}
                            disableSelectionOnClick
                            onSelectionModelChange={(newSelection) => {
                                console.log(newSelection);
                            }}
                        />
                    </Box>
                </Container>
            </Container>
        );
    };

    return (
        <Container fluid style={{ padding: "0px 0px 0px 0px" }} maxWidth={false}>
            {renderProjectList()}
            {showProjectAddForm && (
                <div>
                    <ProjectsAddForm show={showProjectAddForm} onClose={() => setProjectAddForm(false)} />
                </div>
            )}
            {showProjectEditForm && (
                <ProjectsManage open={showProjectEditForm} handleClose={() => setProjectEditForm(false)} projectId={selectedProjectId} />
            )}
            {showManageModal && (
                <ProjectsManage open={showManageModal} handleClose={() => setShowManageModal(false)} />
            )}
        </Container>
    );
};

export default ProjectsHome;
