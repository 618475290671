import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  fetchForms, 
  fetchProjects, 
  fetchFormTemplates,
  newFormFromWebSocket,
  updateFormFromWebSocket,
  deleteFormFromWebSocket,
} from '../../apislice';
import { Button, Box, Typography, Container, IconButton, Modal, TextField, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { AddCircleOutline, GetApp, Refresh } from '@mui/icons-material';
import FormEditor from '../Formbuilder/FormEditor';  // Replace FormFiller with FormEditor
import DownloadPdfButton from './formPdf';  // Add DownloadPdfButton

import { connectWebSocket, disconnectWebSocket } from '../../websocketService';  // Add connectWebSocket, disconnectWebSocket

// Define styles for the modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const FormHomePage = () => {
  const dispatch = useDispatch();
  const forms = useSelector(state => state.api.forms.data);
  const formTemplates = useSelector(state => state.api.formTemplates.data);
  const projects = useSelector(state => state.api.projects.data);
  const [isFormEditorOpen, setIsFormEditorOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [searchText, setSearchText] = useState('');
  const currentUserId = useSelector((state) => state.auth.email.id);

  const templatesLoaded = formTemplates && formTemplates.length > 0;
  const projectsLoaded = projects && projects.length > 0;

  useEffect(() => {
    dispatch(fetchForms());
    dispatch(fetchFormTemplates());
    dispatch(fetchProjects())
  }, [dispatch, templatesLoaded, projectsLoaded]);

  console.log('currentUserId: ', currentUserId);


    //Websocket connection
   useEffect(() => {
      const handleWebSocketMessage = (data) => {
          console.log('WebSocket message received: ', data);
          console.log('currentUserId: ', currentUserId, 'created_by: ', data.created_by);

  
          // Check if the WebSocket message is related to a form created/updated/deleted by another user
          if (data.created_by !== currentUserId) {
            if (data.action === 'created') {
                console.log('New form created:', data);
                dispatch(newFormFromWebSocket(data));  // Send entire data object to Redux
            } else if (data.action === 'updated') {
                console.log('Form updated:', data);
                dispatch(updateFormFromWebSocket(data));  // Handle form update in Redux
            } else if (data.action === 'deleted') {
                console.log('Form deleted:', data.id);
                dispatch(deleteFormFromWebSocket(data.id));  // Remove form from Redux by id
            }
        } else {
            console.log('Ignoring WebSocket message from the current user:', data);
        }
      };
  
      connectWebSocket('formcreator', handleWebSocketMessage);
  
      // Clean up the WebSocket connection when the component unmounts
      return () => {
          disconnectWebSocket('formcreator');
      };
  }, [dispatch, currentUserId]);

  const getProjectName = (projectId) => {
    const project = projects.find(p => p.id === projectId);
    return project ? project.project_name : '';
  };

  const getFormName = (templateId) => {
    const template = formTemplates.find(temp => temp.id === templateId);
    return template ? template.name : 'Unknown Template';
  };

  const getFormRef = (templateId) => {
    const template = formTemplates.find(temp => temp.id === templateId);
    return template ? template.ref : 'Unknown Reference';
  };

  const handleAddForm = () => {
    setSelectedForm(null);
    setIsFormEditorOpen(true);
  };

  const handleEditForm = (form) => {
    setSelectedForm(form);
    setIsFormEditorOpen(true);
  };

  const handleCloseFormEditor = () => {
    setIsFormEditorOpen(false);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleRefreshClick = () => {
    dispatch(fetchForms())
      .then(() => {
        alert('Forms Updated!');
      })
      .catch(() => {
        alert('An error occurred while updating forms.');
      });
  };

  const exportToCsv = (filename, rows) => {
    const csvContent = rows.map(row => Object.values(row).join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const prepareDataForExport = () => {
    return forms.map(form => ({
      ID: form.id,
      Name: getFormName(form.template),
      Reference: getFormRef(form.template),
      Project: getProjectName(form.project),
      Date: new Date(form.created_at).toLocaleDateString(),
    }));
  };

  const filteredForms = forms.filter((form) => {
    const searchLowered = searchText.toLowerCase();
    return (
      getFormName(form.template).toLowerCase().includes(searchLowered) ||
      getFormRef(form.template).toLowerCase().includes(searchLowered) ||
      getProjectName(form.project).toLowerCase().includes(searchLowered)
    );
  });

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'project_name',
      headerName: 'Project',
      flex: 1,
      valueGetter: (params) => getProjectName(params.row.project),
    },
    {
      field: 'form_name',
      headerName: 'Form Name',
      flex: 1,
      valueGetter: (params) => getFormName(params.row.template),
    },
    {
      field: 'form_ref',
      headerName: 'Reference',
      flex: 1,
      valueGetter: (params) => getFormRef(params.row.template),
    },
    {
      field: 'number',
      headerName: 'Number',
      flex : 1,
      valueGetter: (params) => params.row.number,
    },
    {
      field: 'created_at',
      headerName: 'Date',
      flex: 1,
      valueGetter: (params) => new Date(params.row.created_at).toLocaleDateString(),
    },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <IconButton color="primary" onClick={() => handleEditForm(params.row)}>
            <EditIcon />
          </IconButton>
          <DownloadPdfButton formId={params.row.id} />
        </Box>
      ),
    },
  ];

  return (
    <Container maxWidth={false} style={{ padding: "0px 0px 0px 0px" }}>
      <Box sx={{ padding: '10px 0px 0px 0px' }}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
          <Grid item>
            <Typography variant="h4" component="h4" gutterBottom>
              Forms
            </Typography>
          </Grid>
          <Grid item>
            <Box display="flex" gap={1}>
              <Button
                color="primary"
                onClick={handleAddForm}
                startIcon={<AddCircleOutline />}
              >
                Add
              </Button>
              <Button onClick={handleRefreshClick} startIcon={<Refresh />}>
                Refresh
              </Button>
              <Button
                color="primary"
                onClick={() => exportToCsv('forms.csv', prepareDataForExport())}
                startIcon={<GetApp />}
              >
                Download CSV
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '20px' }}>
          <TextField
            id="search"
            size="small"
            variant="outlined"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search Forms..."
            fullWidth
          />
        </Grid>
        <Box sx={{ height: `calc(100vh - 300px)` }}>
          <DataGrid
            rows={filteredForms}
            columns={columns}
            pageSize={10}
            autoHeight={false}
            disableSelectionOnClick
            getRowId={(row) => row.id} // Ensure unique id by using `id` as identifier
          />
        </Box>
      </Box>
      <Modal
        open={isFormEditorOpen}
        onClose={handleCloseFormEditor}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={handleCloseFormEditor}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <FormEditor form={selectedForm} onClose={handleCloseFormEditor} />
        </Box>
      </Modal>
    </Container>
  );
};

export default FormHomePage;
