// src/components/FormBuilder/FormBuilder.js

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFormTemplate,
  fetchFormTemplates,
  updateFormTemplate,
  publishFormTemplate,
} from '../../apislice';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  Alert,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { Delete, Edit, ExpandMore, DragIndicator, Settings } from '@mui/icons-material';
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
  DragOverlay,
  useDroppable,
  useDraggable,
  pointerWithin,
  closestCorners,
  rectIntersection,
  getFirstCollision,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';



// Form Items
import FormItemCustomizer from './FormComponents/FormItemCustomizer';
// import TextAreaItem from './FormComponents/textArea';
import TextInputItem from './FormComponents/TextInputItem';
import CheckboxItem from './FormComponents/CheckboxItem';
import DropdownItem from './FormComponents/DropdownItem';
import DateInputItem from './FormComponents/DateInputItem';
import TableItem from './FormComponents/TableItem';
import HeadingItem from './FormComponents/HeadingItem';

import FormTemplateSettingsModal from './FormComponents/formTemplateSettings';

import { throttle } from 'lodash';

const FormBuilder = () => {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.api.formTemplates.data);

  // State variables
  const [formData, setFormData] = useState([]);
  const [formName, setFormName] = useState('');
  const [formRef, setFormRef] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedVersionId, setSelectedVersionId] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('success');
  const [nameWarning, setNameWarning] = useState('');
  const [refWarning, setRefWarning] = useState('');
  const [isLatestPublishedVersion, setIsLatestPublishedVersion] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeId, setActiveId] = useState(null); // For DragOverlay
  const [tempFormData, setTempFormData] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);
  const previousOverIdRef = useRef(null);
  const throttledSetTempFormData = throttle(setTempFormData, 100);
  const [isSaving, setIsSaving] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isDraftLoading, setIsDraftLoading] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [localTemplates, setLocalTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    if (templates && templates.length > 0) {
      // Map the Redux templates to include the visibility field for each template and its versions
      const syncedTemplates = templates.map(template => ({
        ...template,
        visible: template.visible || true,  // Default visibility to true if not specified
        versions: template.versions.map(version => ({
          ...version,
          visible: version.visible || true,  // Default visibility to true if not specified
        })),
      }));
      setLocalTemplates(syncedTemplates);  // Sync local state with Redux state
    }
  }, [templates]);
  

  // Define available form items (toolbox)
  const availableItems = [
    { id: 'textInput', label: 'Text Input' },
    // { id: 'textArea', label: 'Text Area' },
    { id: 'checkbox', label: 'Checkbox' },
    { id: 'dropdown', label: 'Dropdown' },
    { id: 'dateInput', label: 'Date Input' },
    { id: 'table', label: 'Table' },
    { id: 'heading', label: 'Heading' },
    // Add more form item types as needed
  ];

  useEffect(() => {
    dispatch(fetchFormTemplates());
  }, [dispatch]);

  useEffect(() => {
    setTempFormData(formData);
  }, [formData]);
  

  // Helper functions
  const showAlert = (message, variant = 'success') => {
    setAlertMessage(message);
    setAlertVariant(variant);
    setAlertVisible(true);
    setTimeout(() => setAlertVisible(false), 3000);
  };

  const showSnackBar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpenSettings = (template) => {
    setSelectedTemplate(template);
    setSettingsOpen(true);
  };
  
  const handleCloseSettings = () => {
    setSettingsOpen(false);
  };

  const isDuplicateForm = () => {
    const normalizedFormName = formName.replace(/\s+/g, '').toLowerCase();
    const normalizedFormRef = formRef.toLowerCase();

    return templates.some(
      (template) =>
        template.id !== selectedTemplateId &&
        (template.name.replace(/\s+/g, '').toLowerCase() === normalizedFormName ||
          template.ref.toLowerCase() === normalizedFormRef)
    );
  };

  // Handlers for form actions
  const handleSave = (isDraft = true) => {
    if (!formName.trim() || !formRef.trim()) {
      showSnackBar('Form name and reference are required.', 'error');
      return;
    }
  
    setIsDraftLoading(true); // Start loading spinner
  
    if (isDuplicateForm()) {
      showSnackBar('A form with this name or reference already exists.', 'error');
      setIsDraftLoading(false); // Stop loading spinner
      return;
    }
  
    const formTemplate = {
      name: formName,
      ref: formRef,
      fields: formData,
      is_draft: isDraft,
    };
  
    if (selectedTemplateId) {
      dispatch(updateFormTemplate({ templateId: selectedTemplateId, formTemplate }))
        .then(() => {
          showSnackBar('Template saved as draft.', 'success');
        })
        .finally(() => setIsDraftLoading(false)); // Stop loading spinner
    } else {
      dispatch(addFormTemplate({ formName: formTemplate.name, ref: formTemplate.ref, fields: formTemplate.fields }))
        .then(() => {
          setFormData([]);
          setFormName('');
          setFormRef('');
          showSnackBar('New template created.', 'success');
        })
        .finally(() => setIsDraftLoading(false)); // Stop loading spinner
    }
  
    setHasUnsavedChanges(false);
  };

  const handlePublish = () => {
    setIsPublishLoading(true); // Start loading spinner
  
    if (selectedTemplateId) {
      const formTemplate = {
        name: formName,
        ref: formRef,
        fields: formData,
        is_draft: false,
      };
  
      dispatch(updateFormTemplate({ templateId: selectedTemplateId, formTemplate }))
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            dispatch(publishFormTemplate({ templateId: selectedTemplateId }))
              .then(() => {
                setFormData([]);
                setFormName('');
                setFormRef('');
                showSnackBar('Template published successfully.', 'success');
              });
          } else {
            showSnackBar('Failed to save form before publishing.', 'error');
          }
        })
        .finally(() => setIsPublishLoading(false)); // Stop loading spinner
    }
  };
  

  const handleNewTemplate = () => {
    setFormData([]);
    setFormName('');
    setFormRef('');
    // Removed description
    setSelectedTemplateId(null);
    setSelectedVersionId(null);
    setIsPublished(false);
  };

  const handleVersionSelect = (template, version) => {
    setFormData([]);
    setSelectedTemplateId(template.id);
    setSelectedVersionId(version.id);
    setFormName(template.name);
    setFormRef(template.ref);
    // Removed description
    setIsPublished(!version.is_draft);

    // Determine if this is the latest published version
    const latestPublishedVersion = template.versions
      .filter((v) => !v.is_draft)
      .sort((a, b) => b.version_number - a.version_number)[0];
    const isLatest = latestPublishedVersion && latestPublishedVersion.id === version.id;

    setIsLatestPublishedVersion(isLatest);

    // Deep copy of the fields array
    const fieldsCopy = JSON.parse(JSON.stringify(version.fields));
    setFormData(fieldsCopy);
  };


  const customCollisionDetection = (args) => {
    const pointerIntersections = pointerWithin(args);
    const intersections = pointerIntersections.length > 0 ? pointerIntersections : rectIntersection(args);
  
    let overId = getFirstCollision(intersections, 'id');
  
    return overId ? [{ id: overId }] : [];
  };

  const collisionDetectionStrategy = pointerWithin;

  const handleEdit = () => {
    setIsEditLoading(true); // Start loading spinner
  
    if (isPublished) {
      const formTemplate = {
        name: formName,
        ref: formRef,
        fields: formData,
        is_draft: true,
      };
  
      dispatch(updateFormTemplate({ templateId: selectedTemplateId, formTemplate }))
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            dispatch(fetchFormTemplates()).then(() => {
              const updatedTemplate = templates.find((t) => t.id === selectedTemplateId);
              const newDraftVersion = updatedTemplate.versions.find((v) => v.is_draft);
  
              if (newDraftVersion) {
                setSelectedVersionId(newDraftVersion.id);
                setFormData(newDraftVersion.fields || []);
                showSnackBar('New draft version created.', 'warning');
              }
            });
          } else {
            showSnackBar('Failed to create new draft version.', 'error');
          }
        })
        .finally(() => setIsEditLoading(false)); // Stop loading spinner
    }
  };
  

  // Form input handlers
  const handleFormNameChange = (event) => {
    const value = event.target.value;
    setFormName(value);

    const normalizedValue = value.replace(/\s+/g, '').toLowerCase();
    const nameExists = templates.some(
      (template) =>
        template.id !== selectedTemplateId &&
        template.name.replace(/\s+/g, '').toLowerCase() === normalizedValue
    );

    setNameWarning(nameExists ? 'A form with this name already exists.' : '');
  };

  const handleFormRefChange = (event) => {
    const value = event.target.value;
    setFormRef(value);

    const normalizedValue = value.toLowerCase();
    const refExists = templates.some(
      (template) => template.id !== selectedTemplateId && template.ref.toLowerCase() === normalizedValue
    );

    setRefWarning(refExists ? 'A form with this reference already exists.' : '');
  };

  // Drag-and-Drop Functionality
  const sensors = useSensors(
    useSensor(PointerSensor, { 
      activationConstraint: { 
        distance: 0,
        delay: 150
      },  
    })
  );

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  
    if (active.data.current?.type === 'toolbox') {
      const newItem = {
        id: Date.now().toString(),
        item_type: active.id,
        label: active.data.current.label,
        required: false,
        font_size: 14,
      };
      setDraggingItem(newItem);
    }
  };
  

  // const handleDragOver = (event) => {
  //   const { active, over } = event;
  //   const activeNode = active?.active?.node?.current;
  //   const overNode = over?.node?.current;
  
  //   if (activeNode && overNode && activeNode !== overNode) {
  //     const isBelowOverItem = closestCorners.isBelow(activeNode, overNode);
  
  //     // Determine where to insert the item
  //     let newIndex;
  //     if (isBelowOverItem) {
  //       newIndex = tempFormData.findIndex((item) => item.id === over.id) + 1;
  //     } else {
  //       newIndex = tempFormData.findIndex((item) => item.id === over.id);
  //     }
  
  //     // Create a new array with the item inserted at the new index
  //     const newFormData = [...tempFormData];
  //     if (active.data.current?.type === 'toolbox') {
  //       newFormData.splice(newIndex, 0, draggingItem);
  //     } else {
  //       const oldIndex = newFormData.findIndex((item) => item.id === active.id);
  //       newFormData.splice(newIndex, 0, newFormData.splice(oldIndex, 1)[0]);
  //     }
  
  //     // Update state with the new order
  //     setTempFormData(newFormData);
  //   }
  // };

  const handleDragOver = throttle((event) => {
    const { active, over } = event;
    const activeNode = active?.active?.node?.current;
    const overNode = over?.node?.current;
  
    if (activeNode && overNode && activeNode !== overNode) {
      const isBelowOverItem = closestCorners.isBelow(activeNode, overNode);
      let newIndex;
  
      if (isBelowOverItem) {
        newIndex = tempFormData.findIndex((item) => item.id === over.id) + 1;
      } else {
        newIndex = tempFormData.findIndex((item) => item.id === over.id);
      }
  
      const newFormData = [...tempFormData];
      if (active.data.current?.type === 'toolbox') {
        newFormData.splice(newIndex, 0, draggingItem);
      } else {
        const oldIndex = newFormData.findIndex((item) => item.id === active.id);
        newFormData.splice(newIndex, 0, newFormData.splice(oldIndex, 1)[0]);
      }
  
      // Update state with the new order
      setTempFormData(newFormData);
    }
  }, 100);
  
  
  
  
  
  
  
  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);
    setDraggingItem(null);
  
    if (!over) {
      // Dropped outside any droppable area
      return;
    }
  
    if (active.data.current?.type === 'toolbox') {
      const newItem = draggingItem;
  
      // Add new item to the form if dropped over the form builder area or its items
      if (over.id === 'form-builder-area' || formData.some((item) => item.id === over.id)) {
        const overIndex = formData.findIndex((item) => item.id === over.id);
  
        if (over.id === 'form-builder-area' || overIndex === -1) {
          // Append at the end
          setFormData((prevItems) => [...prevItems, newItem]);
        } else {
          // Insert before the over item
          setFormData((prevItems) => [
            ...prevItems.slice(0, overIndex),
            newItem,
            ...prevItems.slice(overIndex),
          ]);
        }
  
        setHasUnsavedChanges(true);
  
        // Add a short delay to ensure the item is fully added before interactions
        setTimeout(() => {
          console.log('New item added, ready for interaction');
        }, 300); // Delay the interaction slightly
      }
    } else {
      // Reordering existing items
      const activeIndex = formData.findIndex((item) => item.id === active.id);
      const overIndex = formData.findIndex((item) => item.id === over.id);
  
      if (activeIndex !== overIndex && overIndex !== -1) {
        const updatedItems = arrayMove(formData, activeIndex, overIndex);
        setFormData(updatedItems); // Ensure formData is updated
        setHasUnsavedChanges(true);
      }
    }
  };
  
  

  
  
  
  
  

  // Sortable item component for form items
  const SortableItem = React.memo(({ item, isPlaceholder }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: item.id,
    });
  
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      opacity: isDragging || isPlaceholder ? 0.5 : 1,
      backgroundColor: isPlaceholder ? '#f0f0f0' : '#fff',
      border: isDragging ? '2px solid #4caf50' : '1px solid #ccc',
    };
  
    return (
      <Box
        ref={setNodeRef}
        style={style}
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 1,
          p: 1,
          cursor: 'grab',
          borderRadius: '4px',
        }}
      >
        {/* Separate drag handle */}
        <Box 
          {...listeners}
          {...attributes}
          sx={{ mr: 1, cursor: 'grab' }}
        >
          <DragIndicator />
        </Box>
  
        <Box 
          sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="body1" sx={{ fontSize: item.font_size }}>
            {item.label}
          </Typography>
          {!isPlaceholder && (
            <Box>
              <IconButton
                aria-label="edit"
                onClick={(e) => {
                  e.stopPropagation();
                  console.log('Editing item:', item);
                  setSelectedItem(item);
                }}
                size="small"
                sx={{ mr: 1 }}
              >
                <Edit />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteItem(item.id);
                }}
                size="small"
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    );
  });
  

  // Draggable item component for toolbox items


  const DraggableToolboxItem = ({ item, isPublished }) => {
    const { attributes, listeners, setNodeRef } = useDraggable({
      id: item.id,
      data: { type: 'toolbox', label: item.label },
      disabled: isPublished,

    });
  
    return (
      <Box
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        sx={{
          border: '1px solid #ccc',
          mb: 1,
          p: 1,
          backgroundColor: '#fff',
          cursor: 'grab',
          backgroundColor: isPublished ? '#f0f0f0' : '#fff', // Grey out the item when form is published
          cursor: isPublished ? 'not-allowed' : 'grab', // Change cursor when disabled
          opacity: isPublished ? 0.5 : 1, // Reduce opacity when disabled
          transition: 'background-color 0.3s, opacity 0.3s', // Smooth transition for hover and opacity changes
        }}
      >
        <Typography variant="body1">{item.label}</Typography>
      </Box>
    );
  };

  const handleVisibilityToggle = (templateId, isVisible, isFormLevel, versionId = null) => {
    setLocalTemplates((prevTemplates) =>
      prevTemplates.map((template) => {
        if (template.id === templateId) {
          if (isFormLevel) {
            // Toggle form visibility for the whole form
            return {
              ...template,
              visible: isVisible,
              versions: template.versions.map((version) => ({
                ...version,
                visible: isVisible,  // Set all versions to the same visibility as the form
              })),
            };
          } else {
            // Toggle visibility for a specific version
            return {
              ...template,
              versions: template.versions.map((version) =>
                version.id === versionId ? { ...version, visible: isVisible } : version
              ),
            };
          }
        }
        return template;
      })
    );
  };
  
  
  

  const handleDeleteItem = (itemId) => {
    setFormData(formData.filter((item) => item.id !== itemId));
    setHasUnsavedChanges(true);
  };

  // Rendered form items for preview
  const renderFormItems = () => {
    return formData.map((item) => {
      // if (item.item_type === 'textArea') {
      //   return <TextAreaItem key={item.id} item={item} handleChange={() => {}} />;
      // }
      if (item.item_type === 'heading') {
        return <HeadingItem key={item.id} item={item} handleChange={() => {}} />;
      }
      if (item.item_type === 'textInput') {
        return <TextInputItem key={item.id} item={item} handleChange={() => {}} />;
      }
      if (item.item_type === 'checkbox') {
        return <CheckboxItem key={item.id} item={item} handleChange={() => {}} />;
      }
      if (item.item_type === 'dropdown') {
        return <DropdownItem key={item.id} item={item} handleChange={() => {}} />;
      }
      if (item.item_type === 'dateInput') {
        return <DateInputItem key={item.id} item={item} handleChange={() => {}} />;
      }
      if (item.item_type === 'table') {
        return <TableItem key={item.id} item={item} handleChange={() => {}} />;
      }
      return null;
    });
  };

  // Active draggable item for DragOverlay
  const activeItem = activeId && availableItems.find((item) => item.id === activeId);

  // **Added useDroppable hook for the form builder area**
  const { isOver, setNodeRef } = useDroppable({
    id: 'form-builder-area',
  });

  // Functions for preview dialog
  const handleShowPreview = () => {
    setPreviewVisible(true);
  };

  const handleClosePreview = () => {
    setPreviewVisible(false);
  };

  const Droppable = ({ id, children }) => {
    const { isOver, setNodeRef } = useDroppable({
      id: id,
    });
  
    return (
      <Box
        ref={setNodeRef}
        sx={{
          // Ensure the height doesn't cause overlap
          minHeight: '600px',
          border: `2px dashed ${isOver ? '#4caf50' : '#ccc'}`,
          p: 2,
          backgroundColor: isOver ? '#e0f7fa' : 'transparent',
          transition: 'background-color 0.2s ease',
        }}
      >
        {children}
      </Box>
    );
  };

  return (
    // Removed Container's default maxWidth to make it stretch to the edges with small margin
    <Container maxWidth={false} sx={{ margin: '10px' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Form Builder
      </Typography>

      {/* Alert Message */}
      {alertVisible && (
        <Box my={2}>
          <Typography
            variant="body1"
            sx={{
              backgroundColor: alertVariant === 'danger' ? '#f8d7da' : '#d4edda',
              padding: 2,
            }}
          >
            {alertMessage}
          </Typography>
        </Box>
      )}

      <Grid container spacing={2}>
        {/* Templates List */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6">Templates</Typography>
          <IconButton
            onClick={handleOpenSettings}
            size="small"
            sx={{ ml: 1 }}
          >
            <Settings />
          </IconButton>
          <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
            {templates && templates.length > 0 ? (
              templates
                .slice()
                .sort((a, b) => a.ref.localeCompare(b.ref))
                .map((template) => (
                  <Accordion 
                    key={template.id} 
                    defaultExpanded={false}
                    sx={{
                      border: selectedTemplateId === template.id ? '2px solid #2196f3' : '1px solid #ccc', // Blue border if selected
                      backgroundColor: selectedTemplateId === template.id ? '#e3f2fd' : 'white', // Light blue background if selected
                      transition: 'background-color 0.3s, border 0.3s', // Smooth transition
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: selectedTemplateId === template.id ? 'bold' : 'normal',
                          color: selectedTemplateId === template.id ? '#1976d2' : 'black', // Blue text color if selected
                        }}
                      >
                        {`${template.ref} - ${template.name}`}
                      </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                      <List component="div" disablePadding>
                        {template.versions
                          .slice()
                          .sort((a, b) => a.version_number - b.version_number)
                          .map((version) => (
                            <ListItem
                              key={version.id}
                              sx={{ pl: 2 }}
                              onClick={() => handleVersionSelect(template, version)}
                              selected={selectedVersionId === version.id}
                              button
                            >
                              <ListItemText
                                primary={`Version ${version.version_number} ${
                                  version.is_draft ? '(Draft)' : '(Published)'
                                }`}
                              />
                            </ListItem>
                          ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ))
            ) : (
              <Typography>No templates available</Typography>
            )}
          </Box>
        </Grid>


        {/* Form Builder */}
        <Grid item xs={12} md={9}>
          <Box display="flex" alignItems="center" mb={2}>
            <TextField
              label="Form Name"
              variant="outlined"
              value={formName}
              onChange={handleFormNameChange}
              disabled={
                isPublished ||
                templates.some(
                  (template) => template.id === selectedTemplateId && template.versions.some((v) => !v.is_draft)
                )
              }
              error={Boolean(nameWarning)}
              helperText={nameWarning}
              size="small" // Made the TextField smaller
              sx={{ minWidth: '200px' }} // Adjusted width
            />
            <TextField
              label="Reference"
              variant="outlined"
              value={formRef}
              onChange={handleFormRefChange}
              disabled={
                isPublished ||
                templates.some(
                  (template) => template.id === selectedTemplateId && template.versions.some((v) => !v.is_draft)
                )
              }
              error={Boolean(refWarning)}
              helperText={refWarning}
              size="small" // Made the TextField smaller
              sx={{ minWidth: '200px', ml: 2 }} // Adjusted width and added margin-left
            />
            <Button variant="contained" color="primary" onClick={handleNewTemplate} sx={{ ml: 2 }}>
              New Template
            </Button>
          </Box>

          <DndContext
            sensors={sensors}
            collisionDetection={rectIntersection}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver} // Include this line
            onDragEnd={handleDragEnd}
          >
            <Grid container spacing={2}>
              {/* Form Builder Area */}
              <Grid item xs={12} md={9}>
                <Droppable id="form-builder-area">
                  <SortableContext
                    items={tempFormData.map((item) => item.id)}
                    strategy={verticalListSortingStrategy}
                    handle={'.sortable-handle'}
                  >
                    {tempFormData.map((item) => (
                      <SortableItem key={item.id} item={item} />
                    ))}
                  </SortableContext>
                </Droppable>
                          {/* Action Buttons */}
          <Box mt={3} display="flex" justifyContent="flex-end">
            {/* Container for the buttons */}
            <Box display="flex" gap={2}>
              {/* Save as Draft Button */}
              <Button
                variant="outlined"
                onClick={() => handleSave(true)}
                disabled={isDraftLoading || isPublished}
                startIcon={isDraftLoading ? <CircularProgress size={20} /> : null}
                sx={{ minWidth: 150 }} // Consistent button size
              >
                {isDraftLoading ? "Saving..." : "Save as Draft"}
              </Button>

              {/* Conditional rendering for Publish Button */}
              {selectedTemplateId && !isPublished && !hasUnsavedChanges && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={handlePublish}
                  disabled={isPublishLoading || !selectedTemplateId || isPublished}
                  startIcon={isPublishLoading ? <CircularProgress size={20} /> : null}
                  sx={{ minWidth: 150 }} // Consistent button size
                >
                  {isPublishLoading ? "Publishing..." : "Publish"}
                </Button>
              )}

              {/* Preview Form Button */}
              <Button
                variant="contained"
                color="info"
                onClick={handleShowPreview}
                sx={{ minWidth: 150 }} // Consistent button size
              >
                Preview Form
              </Button>

              {/* Conditional rendering for Edit Button */}
              {isPublished && isLatestPublishedVersion && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleEdit}
                  disabled={isEditLoading}
                  startIcon={isEditLoading ? <CircularProgress size={20} /> : null}
                  sx={{ minWidth: 150 }} // Consistent button size
                >
                  {isEditLoading ? "Creating Draft..." : "Edit (New Draft)"}
                </Button>
              )}
            </Box>
          </Box>
              </Grid>


              {/* Toolbox on the Right */}
              <Grid item xs={12} md={3}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Toolbox
                  </Typography>
                  {availableItems.map((item) => (
                    <DraggableToolboxItem key={item.id} item={item} isPublished={isPublished} />
                  ))}
                </Box>
              </Grid>
            </Grid>

            {/* Drag Overlay */}
            <DragOverlay>
              {activeId && draggingItem ? (
                <Box
                  sx={{
                    border: '1px solid #ccc',
                    p: 1,
                    // backgroundColor: '#fff',
                    opacity: 0.8,
                  }}
                >
                  <Typography variant="body1">{draggingItem.label}</Typography>
                </Box>
              ) : null}
            </DragOverlay>
          </DndContext>

          {selectedItem && (
            <FormItemCustomizer
              item={selectedItem}
              updateItem={(updatedItem) => {
                setFormData((items) => items.map((item) => (item.id === updatedItem.id ? updatedItem : item)));
                setHasUnsavedChanges(true);
              }}
              close={() => setSelectedItem(null)}
            />
          )}

          {settingsOpen && (
            <FormTemplateSettingsModal
              open={settingsOpen}
              onClose={handleCloseSettings}
              templates={localTemplates}  // Pass local state with visibility
              handleVisibilityToggle={handleVisibilityToggle}
            />
          )}





          {/* Preview Dialog */}
          <Dialog open={previewVisible} onClose={handleClosePreview} fullWidth maxWidth="md">
            <DialogTitle>{formName ? formName : 'Form Preview'}</DialogTitle>
            <DialogContent>
              {Array.isArray(formData) && formData.length > 0 ? (
                renderFormItems()
              ) : (
                <Typography>No form fields available for preview.</Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePreview}>Close</Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert 
            onClose={() => setSnackbarOpen(false)} 
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Container>
  );
};

export default FormBuilder;
