// src/actions/authActions.js

import axios from 'axios';
import api, { setAuthToken } from '../api/api';



// Action Types
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_SUCCESS
} from './actionTypes';

// Action Creators
export const loginSuccess = (email, id, access, refresh, first_name, last_name, groups ) => ({
  type: LOGIN_SUCCESS,
  payload: {email, id, access, refresh, first_name, last_name, groups},
  
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  setAuthToken(false);

  return{
    type: LOGOUT,
  }
};

// Async action for logging in
export const loginUser = (credentials) => async (dispatch) => {
  try {
    // Make a POST request to the Django API endpoint
    // const response = await axios.post('http://localhost:8000/api/token/', credentials);
    const response = await api.post('token/', credentials);

    if (response.status === 200) {
      // Extract user and token from the API response
      const { refresh, access, email, id, first_name, last_name, groups } = response.data;
      console.log({ refresh, access, email, id, first_name, last_name });
      // dispatch(loginSuccess({ refresh, access}));

      // Store the token in localStorage
      localStorage.setItem('access_token', access);
      localStorage.setItem('refresh_token', refresh);
      axios.defaults.headers.common.Authorization = `Bearer ${access}`;

      setAuthToken(access);


      // Dispatch a success action with the user and token
      dispatch(loginSuccess({ email, access, refresh, id, first_name, last_name, groups }));
    } else {
      // Dispatch a failure action if authentication fails
      dispatch(loginFailure('Authentication failed.'));
    }
  } catch (error) {
    // Dispatch a failure action if an error occurs
    dispatch(loginFailure('An error occurred.'));
  }
};


export const refreshToken = () => async (dispatch, getState) => {
  const refresh = localStorage.getItem('refresh_token') || getState().auth.refresh;

  if (!refresh) {
    console.log("No refresh token available");
    dispatch(logout());
    return Promise.reject('No refresh token available');
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}token/refresh/`, { refresh });
    const newAccessToken = response.data.access;
    localStorage.setItem('access_token', newAccessToken);
    dispatch({ type: 'REFRESH_TOKEN_SUCCESS', payload: newAccessToken });
    return newAccessToken;
  } catch (error) {
    console.log("Failed to refresh token:", error);
    
    // Dispatch a simplified error object to keep Redux state serializable
    dispatch({ 
      type: 'REFRESH_TOKEN_FAILURE', 
      payload: { 
        message: error.message, 
        code: error.code 
      } 
    });
  
    dispatch(logout());
    return Promise.reject(error);
  }
};



